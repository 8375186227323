import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    slider() {
        //メインスライダー
        new Swiper('.anchor-slider', {
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 15,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.anchor-slider .swiper-button-next',
                prevEl: '.anchor-slider .swiper-button-prev',
            },
        });
    }
}

const domReady = () => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.slider();
    });
};

export default function RECRUIT() {
    d.addEventListener('DOMContentLoaded', domReady());
}