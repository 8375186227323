export default class COMMON {
    init() {
        //ハンバーガーメニュー
        const $drowerBtn = $('.drawer_tab');
        const $drowerMenu = $('.drawer_wrap');
        $drowerBtn.on('click', function (e) {
            $(this).toggleClass('active');
            $drowerMenu.toggleClass('open');
            return false;
        });

        window.setTimeout(function () {
            $drowerMenu.addClass('js-load');
        }, 1000);

        //アコーディオン
        const $qaQuestion = $('._question');
        const $qaAnswer = $('._answer');
        $qaAnswer.hide();
        $qaQuestion.on('click', function () {
            $(this).toggleClass('is-active');
            $(this).next().slideToggle();
        });

        $(window).on('lead scroll', function () {
            var elem = $('.effect-act');
            var isAnimate = 'is-scroll';
            elem.each(function () {
                var elemOffset = $(this).offset().top - 150;
                var scrollPos = $(window).scrollTop();
                var wh = $(window).height();

                if (scrollPos > elemOffset - wh + wh / 2) {
                    $(this).addClass(isAnimate);
                }
            });
        });
    }
}
