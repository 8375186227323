import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
Swiper.use([Navigation]);

const [d] = [document];

const domReady = () => {
    const swiper = new Swiper('.topics_slider .swiper', {
        modules: [Navigation],
        loop: true,
        // autoplay: {
        //     delay: 5000,
        //     disableOnInteraction: false,
        //     waitForTransition: false,
        // },
        slidesPerView: 1,
        spaceBetween: 24,
        navigation: {
            nextEl: '.swiper-button-next'
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    });
};

export default function HOME() {
    d.addEventListener('DOMContentLoaded', domReady());
}
